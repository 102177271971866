import React from "react"
import { graphql } from "gatsby"
import Header from "../components/Layout/header"
import Footer from "../components/Layout/footer"
import SEO from "../components/Global/MetaHead"

const PubOferta = ({ data }) => {
  const pageSlug = "publichna-oferta"
  return (
    <>
      <SEO
        lang="uk"
        title="Публічна оферта Центру Ліцензій України"
        description="Предметом цього договору є відносини між веб-ресурсом та користувачем, який користується сервісами веб-ресурсу."
        image={`https:${data.ogImage.fixed.src}`}
        url={`/${pageSlug}/`}
        noindex="noindex"
      />
      <Header />
      <section className="publichna-oferta container py-5">
        <h1 className="text-center">Договір оферти</h1>
        <ol className="my-5">
          <li>
            Предметом цього договору є відносини між Сайтом лицензии.укр та
            користувачем, який користується сервісами Сайту.
          </li>
          <li>
            Користувачем визнається будь-яка фізична особа, яка здійснює доступ
            до Сайту за допомогою мережі Інтернет. Ця Угода стає дійсною з
            Користувачем при вході на Сайт. Подальше використання Сайту є
            підтвердженням згоди Користувача з умовами цієї Угоди. Використання
            Користувачем Сайту, будь-яких його послуг, означає беззастережну
            згоду Користувача з усіма пунктами цієї Угоди і беззастережне
            прийняття його умов.
          </li>
          <li>
            Адміністратор залишає за собою право на свій розсуд змінювати Угоду
            в будь-який час без спеціального попереднього і (або) подальшого
            повідомлення Користувача. Продовження використання Користувачем
            Сайту після будь-яких змін Угоди передбачає згоду Користувача з
            такими змінами.
          </li>
          <li>
            Адміністратор надає Користувачеві доступ до Сайту з основною метою
            надання Користувачу доступу до модулів електронного врядування (далі
            - Контент). Доступ до Контенту на Сайті надається Користувачам
            виключно для некомерційного використання.
          </li>
          <li>
            Користувач підтверджує, що досяг 18-річного віку. Користувач, який
            не досяг необхідного віку, зобов'язується утриматися від
            користування веб-ресурсом.
          </li>
          <li>
            Користувач гарантує, що при доступі до Контенту не здійснює і не
            буде здійснювати будь-яких дій, спрямованих на обхід технічних
            засобів захисту.
          </li>
          <li>
            Доступ до Сайту і Контенту надається "в тому вигляді, як є", і
            Адміністратор не гарантує відповідність послуг, що надаються
            очікуванням Користувача.
          </li>
          <li>
            Користувач розуміє і погоджується з тим, що Адміністратор може, на
            свій розсуд і без спеціального повідомлення Користувача, змінювати
            набір Контенту та / або функціонал Сайту.
          </li>
          <li>
            Користувач розуміє і погоджується з тим, що Адміністратор не
            гарантує безпомилкову та безперебійну роботу Сайту і за
            замовчуванням не несе відповідальності за шкоду, заподіяну
            Користувачеві технічними збоями апаратного або програмного
            забезпечення на будь-якій стороні.
          </li>
          <li>
            Користувач погоджується з використанням Адміністратором персональних
            даних Користувача з метою забезпечення роботи Сайту, надання
            Користувачу послуг доступу до Контенту, а також інформаційного
            обслуговування Користувача. Користувач погоджується на отримання
            повідомлень від Адміністратора на вказану Користувачем при
            реєстрації адресу електронної пошти. Адміністратор зобов'язується не
            розкривати інформацію про Користувача третім особам без згоди
            Користувача, за винятком розкриття
          </li>
          інформації на вимогу суду та / або правоохоронних органів відповідно
          до застосовного законодавства.
          <li>
            Адміністратор залишає за собою право в будь-який час на свій розсуд
            припиняти, обмежувати або припиняти доступ Користувача до Сайту або
            до окремих послуг Сайту, в тому числі, але не виключно, у разі
            порушення Користувачем цієї Угоди та / або чинного законодавства.
          </li>
          <li>
            Адміністрація Сайту, ТОВ "Центр Ліцензій" не несе відповідальності
            за можливу шкоду заподіяну третім особам діями користувачів Сайту чи
            контентом, який розміщюється на Сайті Користувачем.
          </li>
        </ol>
        <h2>Згода на збір та обробку персональних даних</h2>
        <ol className="my-5">
          <li>
            Шляхом проставлення відмітки на сторінці або при оформленні
            замовлення на інтернет-сайті лицензии.укр в пункті «Згода з
            правилами сайту» Користувач – фізична особа, суб’єкт персональних
            даних, надає ТОВ «ЦЕНТР ЛІЦЕНЗІЙ» свою безумовну згоду на збір і
            обробку персональних даних.
          </li>
          <li>
            Я, як Користувач інтернет-сайту лицензии.укр, надаю свою згоду ТОВ
            «ЦЕНТР ЛІЦЕНЗІЙ» на обробку, використання та зберігання моїх
            персональних даних у картотеках та/або за допомогою бази
            персональних даних, власником якої є ТОВ «ЦЕНТР ЛІЦЕНЗІЙ» (далі –
            Власник) і яка знаходиться за адресою: 03164, м.Київ, вул. Підлісна,
            1, оф. 8.
          </li>
          <li>
            Шляхом проставляння відмітки на сторінці або при оформленні
            замовлення на інтернет-сайті лицензии.укр в пункті «Згода з
            правилами сайту», підтверджую, що повідомлений(-а) і згодний(-а) з
            наступним:
          </li>
          <ul>
            <li>
              Власник здійснює обробку персональних даних Користувача будь-якими
              способами з метою належного надання Користувачу послуг
              (ідентифікація, аутентифікація, авторизація, знеособлення,
              відновлення пароля, відправка інформаційних матеріалів за згодою
              Користувача, відповідей на запити та листи Користувача, отримання
              заявок на придбання товару, залишення відгуків щодо якості
              обслуговування Власника, отримання рекламних та спеціальних
              пропозицій, інформації про акції, розіграші, іншу інформацію про
              діяльність Власника з метою проведення успішних і безпечних
              транзакцій замовлень і оплат; здійснення пропонованих послуг;
              проведення маркетингових акцій та вручення призових винагород);
            </li>
            <li>
              Під час користування інтернет-сайтом Власник здійснює збір та
              обробку персональних даних Користувача, пов'язаних з
              ідентифікацією, аутентифікацією, авторизацією, знеособленням,
              відновленням пароля, відправкою інформаційних матеріалів за згодою
              Користувача, відповідями на запити та листи Користувача,
              отриманням заявок на придбання товару, залишенням відгуків щодо
              якості обслуговування Власника, для реєстрації в програмі знижок
              Власника, отримання рекламних та спеціальних пропозицій,
              інформації про акції, розіграші, іншої інформації про діяльність
              Власника, здійснення пропонованих послуг; проведення маркетингових
              акцій та вручення призових винагород, серед яких можуть бути
              наступні персональні дані:
            </li>
            <ul>
              <li>
                прізвище, ім'я, по батькові, адреса доставки замовлень,
                контактний номер телефону, e-mail адресу, дані по замовленнях,
                бонуси-знижки, інші відомості, що містять персональні дані в
                електронній формі;
              </li>
              <li>
                персональні дані, надані Користувачем при заповненні
                реєстраційних форм на інтернет-сайті, а так само у процесі
                користування інтернет-сайтом;
              </li>
              <li>
                файли cookie (кукі-файли). Кукі-файли є інформаційними файлами,
                які надходять до комп’ютера або мобільного пристрою, коли той
                використовується для відвідування сайтів. ТОВ «ЦЕНТР ЛІЦЕНЗІЙ»
                не надає можливості третім сторонам поміщати кукі-файли на ваш
                пристрій. За допомогою кукі-файлів ТОВ «ЦЕНТР ЛІЦЕНЗІЙ» може
                збирати інформацію про ваше користування нашим сайтом, вашу
                IP-адресу, тип браузера та операційної системи. Також з метою
                опрацювання ваших покупок, здійснених у режимі онлайн, ми можемо
                збирати вашу платіжну інформацію, інформацію про ваші уподобання
                щодо продукції тощо.
              </li>
            </ul>
            <li>
              Власник включає дані Користувача в базу персональних даних
              «Фізичні особи які приймають участь у господарській діяльності ТОВ
              «ЦЕНТР ЛІЦЕНЗІЙ» з моменту, коли Користувач вперше надає
              (надсилає) свої персональні дані Власнику шляхом заповнення форм
              на інтернет-сторінці Власника.
            </li>
            <li>
              Натискаючи на кнопку «Відправити» або «Надіслати», я добровільно
              погоджуюся надати зазначені вище персональні дані про себе для
              загального доступу необмеженому колу осіб в рамках використання
              Бази персональних даних «Фізичні особи які приймають участь у
              господарській діяльності ТОВ «ЦЕНТР ЛІЦЕНЗІЙ».
            </li>
            <li>
              Власник використовує знеособлені дані для таргетингу рекламних
              та/або інформаційних матеріалів за віком, статтю, іншими даними;
              для проведення статистичних досліджень; іншими способами.
            </li>
            <li>
              Власник бази даних не інформує суб'єкта персональних даних про
              передачу його персональних даних у випадках, передбачених Законом
              України «Про захист персональних даних».
            </li>
          </ul>
          <li>
            Користувач має всі права щодо захисту його персональних даних,
            передбачених чинним законодавством України, зокрема, Законом України
            «Про захист персональних даних», а саме:
          </li>
          <ul>
            <li>
              знати про джерела збирання, місцезнаходження своїх персональних
              даних, мету їх обробки, місцезнаходження або місце проживання
              (перебування) володільця чи розпорядника персональних даних або
              дати відповідне доручення щодо отримання цієї інформації
              уповноваженим ним особам, крім випадків, встановлених законом;
            </li>
            <li>
              отримувати інформацію про умови надання доступу до персональних
              даних, зокрема інформацію про третіх осіб, яким передаються його
              персональні дані;
            </li>
            <li>на доступ до своїх персональних даних;</li>
            <li>
              отримувати не пізніш як за тридцять календарних днів з дня
              надходження запиту, крім випадків, передбачених законом, відповідь
              про те, чи обробляються його персональні дані, а також отримувати
              зміст таких персональних даних;
            </li>
            <li>
              пред’являти вмотивовану вимогу володільцю персональних даних із
              запереченням проти обробки своїх персональних даних;
            </li>
            <li>
              пред'являти вмотивовану вимогу щодо зміни або знищення своїх
              персональних даних будь-яким володільцем та розпорядником
              персональних даних, якщо ці дані обробляються незаконно чи є
              недостовірними;
            </li>
            <li>
              на захист своїх персональних даних від незаконної обробки та
              випадкової втрати, знищення, пошкодження у зв'язку з умисним
              приховуванням, ненаданням чи несвоєчасним їх наданням, а також на
              захист від надання відомостей, що є недостовірними чи ганьблять
              честь, гідність та ділову репутацію фізичної особи;
            </li>
            <li>
              звертатися із скаргами на обробку своїх персональних даних до
              Уповноваженого або до суду;
            </li>
            <li>
              застосовувати засоби правового захисту в разі порушення
              законодавства про захист персональних даних;
            </li>
            <li>
              вносити застереження стосовно обмеження права на обробку своїх
              персональних даних під час надання згоди;
            </li>
            <li>відкликати згоду на обробку персональних даних;</li>
            <li>знати механізм автоматичної обробки персональних даних;</li>
            <li>
              на захист від автоматизованого рішення, яке має для нього правові
              наслідки.
            </li>
          </ul>
          <li>
            Власник приймає всі передбачені законодавством заходи для захисту
            персональних даних Користувача, зокрема, обробка даних здійснюється
            на обладнанні, яке знаходиться в охоронюваних приміщеннях з
            обмеженим доступом.
          </li>
          <li>
            Шляхом проставляння позначки на сторінці реєстрації або при
            оформленні замовлення на інтернет-сайті ТОВ «ЦЕНТР ЛІЦЕНЗІЙ» в
            нункті «Згода з правилами сайту», я також підтверджую, що
            повідомлений (-а) про включення інформації про мене до бази
            персональних даних з вищезазначеною метою, про мої права, які
            визначені Законом України «Про захист персональних даних», а також
            про осіб, яким мої дані передаються для виконання вищезазначеної
            мети.
          </li>
          <li>
            Сайт лицензии.укр може містити посилання на сайти, які не
            відносяться до ТОВ «ЦЕНТР ЛІЦЕНЗІЙ». Такі гіперпосилання наведені
            виключно для інформаційних цілей. При використанні таких веб-сайтів
            ми рекомендуємо Вам уважно прочитати їх політику конфіденційності.
          </li>
          <li>
            Зазначена угода надана з урахуванням вимог ст.7, ст.8 і ст.11 Закону
            України «Про захист персональних даних» та діє без обмеження
            території дії.
          </li>
          <li>
            Я цим підтверджую, що отримав повідомлення про Власника персональних
            даних, склад та зміст зібраних персональних даних, а також відомості
            про мої права, визначених законом України «Про захист персональних
            даних» для виконання зазначеної мети і можливості передачі моїх
            персональних даних третім особам на розсуд Власника і /або
            розпорядника бази даних.
          </li>
          <li>
            Зміст моїх прав як суб'єкта персональних даних відповідно до Закону
            України «Про захист персональних даних» мені повідомлено і
            зрозуміло.
          </li>
          <li>
            Зазначена угода діє безстроково до дати її відкликання мною шляхом
            направлення на адресу ТОВ «ЦЕНТР ЛІЦЕНЗІЙ» письмового повідомлення
            про вказане відкликання в довільній формі, якщо інше не встановлено
            чинним законодавством України.
          </li>
          <li>
            ТОВ «ЦЕНТР ЛІЦЕНЗІЙ» залишає за собою право в будь-який час внести
            зміни до даного положення. Після внесення змін, такі зміни вступлять
            в силу відразу ж після розміщення їх на офіційному сайті ТОВ «ЦЕНТР
            ЛІЦЕНЗІЙ».
          </li>
        </ol>
      </section>
      <Footer slug={`/${pageSlug}/`} />
    </>
  )
}
export const query = graphql`
  {
    ogImage: contentfulAsset(
      file: { fileName: { eq: "center-licenziy-opengraph-min.png" } }
    ) {
      title
      id
      fixed(width: 620, height: 460) {
        src
      }
    }
  }
`

export default PubOferta
